
<template>
  <div>
    <div>
      <div>
        <div>
          <Divider dashed style="color:#fff">可用画面</Divider>
          <template v-if="imageFileObj.list && imageFileObj.list.length">
            <Row :gutter="8">
              <i-col :xs="24" :sm="12" :md="12" :lg="12" v-for="(image, imgIndex) in imageFileObj.list" :key="imgIndex">
                <a href="javascript:;" @click="handlePreview(image)">
                  <img :src="formatImageUrl(image.fileKey, 240, 0)" style="width:100%;height:80px;">
                  <div class="m-b-5" style="color:#fff">
                    <Tooltip :content="image.fileName" :transfer="true">
                      {{ image.fileName.length > 14 ? image.fileName.substr(0, 13) : image.fileName }}
                    </Tooltip>
                  </div>
                </a>
              </i-col>
            </Row>
            <div class="text-center m-t-10">
              <Page size="small" :total="imageFileObj.totalRow" :page-size="query.pageSize" show-total
                @on-change="changePageImgage" />
            </div>
          </template>
          <div v-else class="text-center">暂无可用素材</div>
        </div>
        <div>
          <Divider dashed style="color:#fff">可用视频</Divider>
          <template v-if="videoFileObj.list && videoFileObj.list.length">
            <Row :gutter="8">
              <i-col :xs="24" :sm="12" :md="12" :lg="12" v-for="(video, videoIndex) in videoFileObj.list"
                :key="videoIndex">
                <Card class="text-center" style="word-break: break-all;word-wrap: break-word;min-height:80px;">
                  <a @click="handleVideoPreview(video)" style="color:#fff">{{ video.fileName }} (<span
                      class="text-orange">{{ video.duration }}</span>秒)</a>
                </Card>
              </i-col>
            </Row>
            <div class="text-center m-t-10">
              <Page size="small" :total="videoFileObj.totalRow" :page-size="query.pageSize" show-total
                @on-change="changePageVideo" />
            </div>
          </template>
          <div v-else class="text-center">暂无可用素材</div>
        </div>
        <div>
          <Divider dashed style="color:#fff">可用音频</Divider>
          <template v-if="voiceFileObj.list && voiceFileObj.list.length">
            <Row :gutter="8">
              <i-col :xs="24" :sm="12" :md="12" :lg="12" v-for="(voice, voiceIndex) in voiceFileObj.list"
                :key="voiceIndex">
                <Card class="text-center" style="word-break: break-all;word-wrap: break-word;min-height:80px;">
                  <a @click="handleVideoPreview(voice)" style="color:#fff">{{ voice.fileName }} (<span
                      class="text-orange">{{ voice.duration }}</span>秒)</a>
                </Card>
              </i-col>
            </Row>
            <div class="text-center m-t-10">
              <Page size="small" :total="voiceFileObj.totalRow" :page-size="query.pageSize" show-total
                @on-change="changePageVideo" />
            </div>
          </template>
          <div v-else class="text-center">暂无可用素材</div>
        </div>
      </div>
    </div>
    <!-- 上刊材料预览（画面、视频） -->
    <Modal v-model="visibleImage" width="800" :footer-hide="true">
      <p slot="header">{{ fileName }}</p>
      <img :src="imageUrl + '?x-oss-process=image/resize,w_968'" v-if="visibleImage" style="width: 100%">
    </Modal>
    <Modal v-model="visibleVideo" :footer-hide="true">
      <p slot="header">{{ fileName }}</p>
      <video :src="videoUrl" id="videoShow" controls="controls" style="width:100%">
        您的浏览器不支持预览视频。
      </video>
    </Modal>
  </div>
</template>

<script>
import { sysMixins } from '@/assets/mixins/sys'
import { agreeFile } from '@/api/order/orderfile'
export default {
  mixins: [sysMixins],
  data () {
    return {
      query: {
        approvalDate: '',
        orderId: null,
        pageNumber: 1,
        pageSize: 12,
        advertiserId: null,
        brandId: null,
        types: ''
      },
      imageFileObj: {},
      videoFileObj: {},
      voiceFileObj: {},
      visibleImage: false,
      imageUrl: '',
      visibleVideo: false,
      videoUrl: '',
      fileName: ''
    }
  },
  computed: {
    orderInfo () {
      return this.$store.state.ownerOrder.orderBean
    }
  },
  methods: {
    /**
     * 获取上传的素材
     */
    getExistsFiles (type = 1, pageNumber = 1) {
      this.query.orderId = this.orderInfo.id
      this.query.pageNumber = pageNumber
      this.query.advertiserId = this.orderInfo.advertiserId
      this.query.brandId = this.orderInfo.brandId
      this.query.types = JSON.stringify([type])
      return new Promise((resolve) => {
        agreeFile(this.query).then(res => {
          if (res && !res.errcode) {
            resolve(res)
          }
        })
      })
    },
    changePageImgage (curPage) {
      this.getExistsFiles(1, curPage).then(res => {
        this.imageFileObj = res
      })
    },
    changePageVideo (curPage) {
      this.getExistsFiles(3, curPage).then(res => {
        this.videoFileObj = res
      })
    },
    formatImageUrl (fileUrl, width, height) {
      const widthstr = (width === 0) ? '' : ',w_' + width
      const heightstr = (height === 0) ? '' : ',h_' + height

      return fileUrl + '?x-oss-process=image/resize,m_lfit' + widthstr + heightstr
    },
    handlePreview (file) {
      this.imageUrl = file.fileKey
      this.fileName = file.fileName
      this.visibleImage = true
    },
    handleVideoPreview (file) {
      this.videoUrl = file.fileKey
      this.fileName = file.fileName
      this.visibleVideo = true
    }
  },
  watch: {
    orderInfo: {
      handler (newVal, oldVal) {
        if (newVal && newVal.brandId) {
          this.getExistsFiles(1).then(res => {
            this.imageFileObj = res
          })

          this.getExistsFiles(3).then(res => {
            this.videoFileObj = res
          })

          this.getExistsFiles(4).then(res => {
            this.voiceFileObj = res
          })
        }
      },
      deep: true,
      immediate: true
    },
    visibleVideo (val) {
      if (!val) {
        window.document.getElementById('videoShow').pause()
      }
    }
  }
}
</script>
